.singlePage-section {
  padding-top: 162px;
  padding-bottom: 96px;
  .singlePage__like-wrapper {
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      &:last-child {
        margin-top: 50px;
      }
      span {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #000000;
      }
    }
    .sticky {
      position: sticky;
      top: 212px;
      z-index: -1;
    }
  }
  .singlePage__body {
    max-width: 734px;
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .singlePage {
    padding-left: 90px;
    display: flex;
    gap: 128px;
  }
  .singlePage__top-text {
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
  }
  .singlePage__title {
    padding-bottom: 25px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #474747;
  }
  .singlePage__decsription {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #474747;
  }
  .singlePage__time-wrapper {
    padding-bottom: 45px;
    display: flex;
    gap: 42px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #777777;
    time {
      position: relative;
      z-index: -1;
      &::after {
        content: "";
        width: 20px;
        display: inline-block;
        position: absolute;
        top: 7px;
        right: -19px;
        border: 1px solid #d1d1d1;
        transform: rotate(90deg);
      }
    }
    span {
      position: relative;
      z-index: -1;
      &::before {
        content: url("../../assets/img/clock.svg");
        position: absolute;
        top: -2px;
        left: -25px;
      }
    }
  }
  .singlePage__main-text {
    padding-bottom: 80px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #474747;
  }
  .last-wrapper__title {
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .singlePage__last-wrapper {
    margin-bottom: 46px;
    border-bottom: 1px solid #dfdfdf;
    &:last-child {
      margin-bottom: 0;
    }
    .date__wrapper {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      time {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #777777;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #777777;
      }
    }
    .singlePage__content-wrapper {
      max-width: 544px;
      width: 100%;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      h4 {
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        color: #474747;
      }
      p {
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #474747;
      }
    }
    .singlePage__time {
      display: inline-block;
      padding-bottom: 17px;
      margin-left: 30px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #777777;
      position: relative;
      z-index: -1;
      &::before {
        content: url("../../assets/img/clock.svg");
        position: absolute;
        left: -30px;
        top: -1px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .singlePage-section {
    padding-bottom: 50px;
  }
  .singlePage {
    padding-left: 0 !important;
  }
  .singlePage__like-wrapper {
    display: none;
  }
}
