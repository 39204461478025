.site-login {
	height: 100vh;
}

.login_header {
	margin-bottom: 50px;
	padding: 26px 0;
	display: flex;
	align-items: center;
	gap: 15px;
	strong {
		font-weight: 500;
		font-size: 25px;
		line-height: 33px;
		color: #000000;
	}
}

.form {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	h3 {
		margin-bottom: 50px;
		max-width: 759px;
		font-weight: 500;
		font-size: 25px;
		line-height: 33px;
		text-align: center;
		color: #000000;
	}

	form {
		width: 100%;
		max-width: 350px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		span {
			display: inline-block;
			margin-bottom: 15px;
			font-weight: 700;
			font-size: 40px;
			line-height: 53px;
			color: #000000;
		}
		h5 {
			margin-bottom: 10px;
			color: red;
		}
		input {
			margin-bottom: 24px;
			padding: 12px 0 12px 14px;
			width: 100%;
			border: solid 1px transparent;
			background: #f4f4f4;
			border-radius: 3px;
			&::placeholder {
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				color: #474747;
			}
		}
		button {
			padding: 11px 0;
			max-width: 350px;
			width: 100%;
			background: #000000;
			cursor: pointer;
			border-radius: 3px;
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			color: #ffffff;
		}
	}
	.err {
		input {
			border: solid red 1px !important;
		}
	}
}
