/* roboto-mono-300 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("./assets/fonts/roboto-mono-v22-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/roboto-mono-v22-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-500 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("./assets/fonts/roboto-mono-v22-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/roboto-mono-v22-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-regular - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("./assets/fonts/roboto-mono-v22-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/roboto-mono-v22-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-600 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("./assets/fonts/roboto-mono-v22-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/roboto-mono-v22-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-700 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("./assets/fonts/roboto-mono-v22-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/roboto-mono-v22-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  transition: all 0.3s ease;
}

a {
  color: white;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto Mono", sans-serif;
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1320px;
}
