.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .modal {
    padding: 16px 17px;
    max-width: 400px;
    width: 100%;
    min-height: 100px;
    position: fixed;
    top: 100px;
    right: 0;
    background: #ffffff;
    box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.25);
    margin-right: 120px;
  }
  .modal__title {
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #474747;
  }
  .time__wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    h4 {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #474747;
    }
    time {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: #777777;
    }
  }
  .modal__body {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #474747;
    &:hover {
      p {
        text-decoration: underline;
      }
    }
    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #474747;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .modal-wrapper {
    .modal {
      margin-right: 80px;
    }
  }
}

@media only screen and (max-width: 1376px) {
  .modal-wrapper {
    .modal {
      margin-right: 50px;
    }
  }
}

@media only screen and (max-width: 1320px) {
  .modal-wrapper {
    .modal {
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .modal-wrapper {
    .modal {
      margin: 70px 20px 0;
    }
  }
}

@media only screen and (max-width: 445px) {
  .modal-wrapper {
    .modal {
      padding-left: 40px;
    }
  }
}
