.data-section {
  padding: 162px 0px 50px 0;
  .data {
    display: flex;
  }
  .data__posts {
    max-width: 736px;
    width: 100%;
  }
  .posts__title {
    padding-bottom: 58px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #474747;
  }
  .posts__wrapper {
    padding-bottom: 15px;
    margin-bottom: 46px;
    border-bottom: 1px solid #dfdfdf;
  }
  .date__wrapper {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    time {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #777777;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #777777;
    }
  }
  .post__title {
    max-width: 544px;
    width: 100%;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    h4 {
      padding-bottom: 15px;
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
      color: #474747;
    }
    p {
      padding-bottom: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #474747;
    }
  }
  .post__time {
    margin-left: 30px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #777777;
    position: relative;
    z-index: -1;
    &::before {
      content: url("../../assets/img/clock.svg");
      position: absolute;
      left: -30px;
      top: -1px;
    }
  }
  .content__pagenation {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    padding: 3px 0px;
    margin-bottom: 130px;

    div {
      display: flex;
      flex-direction: row;
      text-align: center;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      button {
        padding: 2px;
        outline: none;
        background-color: #fff;
        border: none;
        color: black;
        cursor: pointer;
        &:hover {
          color: #ffba9d;
        }
      }

      &::before {
        content: "<";
        display: block;
        background-color: #f4f4f4;
        padding: 0px 10px 3px;
        cursor: pointer;
      }
      &::after {
        content: ">";
        display: block;
        background-color: #f4f4f4;
        padding: 0px 10px 3px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .data-section {
    padding: 190px 0px 50px 0 !important;
  }
}

@media only screen and (max-width: 500px) {
  .allCategoryStyles-section {
    padding-top: 50px;
    .posts__title {
      padding-bottom: 40px;
    }
  }
}
